@import '~dde-app/common/styles/globals';

$dds-button-border-radius:  $dds-border-radius-global;
$dds-button-letter-spacing: -1px;

$dds-button-primary-color:     $dds-color-white;
$dds-button-primary-bg:        $dds-color-primary;
$dds-button-primary-bg-hover:  lighten($dds-button-primary-bg, 5);
$dds-button-primary-bg-active: darken($dds-button-primary-bg , 5);

$dds-button-secondary-color:     $dds-color-white;
$dds-button-secondary-bg:        $dds-color-secondary;
$dds-button-secondary-bg-hover:  lighten($dds-button-secondary-bg , 5);
$dds-button-secondary-bg-active: darken($dds-button-secondary-bg , 5);

$dds-button-light-color:     $dds-color-gray-700;
$dds-button-light-bg:        $dds-color-gray-100;
$dds-button-light-border:    $dds-color-gray-200;
$dds-button-light-bg-hover:  $dds-color-gray-200;
$dds-button-light-bg-active: $dds-color-gray-300;
