@import '~dde-app/common/styles/globals';

// sass-lint:disable-block no-vendor-prefixes
.TextInput-Input {
  @include dds-form-placeholder;
  @include dds-type-scale(body-m);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  border-radius: $dds-border-radius-global;
  box-shadow: inset 0 0 0 1px $dds-color-border-input;
  font-weight: $dds-font-weight-extrabold;
  letter-spacing: -1.3px;
  padding: $dds-spacing-1 $dds-spacing-1_5;
  text-transform: uppercase;
  width: 100%;

  &:focus {
    box-shadow: inset 0 0 0 2px $dds-color-border-input;
    outline: none;
  }
}
