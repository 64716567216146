@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';
@import './design-tokens';

.PhotoContest {
  background-image: $photo-contest-section-bg;
  padding: $dds-spacing-15 0;
}

.PhotoContest-Title {
  margin-bottom: $dds-spacing-8;
  text-align: center;
}

.PhotoContest-UserName {
  @include dds-type-scale(body-m, 0);
}

.PhotoContest-ContentWrapper {
  margin-bottom: $dds-spacing-10;
}

.PhotoContest-Subtitle {
  @include dds-type-scale(h2);
  margin-bottom: $dds-spacing-2;
  text-transform: none;
}

.PhotoContest-Paragraph {
  margin-bottom: $dds-spacing-2;
}

.PhotoContest-ModalContentBold,
.PhotoContest-ParagraphBold {
  font-weight: $dds-font-weight-bold;
}

.PhotoContest-PhotoImage {
  height: max-content;
  margin: 0 $dds-spacing-2 $dds-spacing-2;
}

.PhotoContest-PrizeImage {
  margin-bottom: $dds-spacing-3;
  max-width: 100%;
}

.PhotoContest-ImageBox {
  display: flex;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
}

.PhotoContest-OpenGalleryButton {
  display: inline-block;
  margin-top: $dds-spacing-10;
}
