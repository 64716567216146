@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';
@import './design-tokens';

.Subscriptions {
  background-color: $dds-color-white;
}

.Subscriptions-BackgroundBox {
  border-radius: $dds-border-radius-global;
  box-shadow: $subscriptions-box-shadow;
  margin: $dds-spacing-4 0;
  padding: $dds-spacing-4 $dds-spacing-2;

  @include media-breakpoint-up(lg) {
    padding: $dds-spacing-4 $dds-spacing-2 0;
  }
}

.Subscriptions-Title {
  margin-bottom: $dds-spacing-2;
  text-align: center;
}

.Subscription-Box {
  display: flex;
  flex-direction: column;
  margin-bottom: $dds-spacing-4;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.Subscription-Content {
  display: flex;
  flex-direction: row;
}

.Subscriptions-EmailMessage {
  @include dds-font-size(small);
  color: $dds-color-error;
  font-weight: $dds-font-weight-bold;

  @include media-breakpoint-up(lg) {
    align-self: self-start;
    margin-left: $dds-spacing-8;
  }

  &--Error {
    color: $dds-color-error;
  }

  &--Success {
    color: $dds-color-primary;
  }
}

.Subscriptions-EmailMessageIcon {
  margin-right: $dds-spacing-0_5;
}

.Subscription-Button {
  margin-top: $dds-spacing-2;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    align-self: self-start;
    margin-left: $dds-spacing-8;
  }
}

.Subscription-Text {
  @include dds-font-size(0);
  margin-left: $dds-spacing-2;
}

.Subscription-Icon {
  height: $dds-sizing-4;
  width: $dds-sizing-4;

  @include media-breakpoint-up(lg) {
    height: $dds-sizing-6;
    width: $dds-sizing-6;
  }
}

.Subscription-Form {
  display: flex;
  margin-bottom: $dds-spacing-1;
  margin-top: $dds-spacing-2;

  .TextInput-Input {
    height: $dds-sizing-5;
  }

  @include media-breakpoint-up(lg) {
    align-self: self-start;
    margin-left: $dds-spacing-8;
  }
}

.Subscription-FormButton {
  align-self: flex-start;
  margin-left: $dds-spacing-1;
}

.Subscriptions--MapEmbedded {
  .Subscriptions-BackgroundBox {
    background-color: $dds-color-gray-100;
    box-shadow: unset;
    margin: $dds-spacing-1 0 $dds-spacing-2;
    padding: $dds-spacing-2 $dds-spacing-1;
  }

  .Subscription-Form {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  .Subscription-FormButton,
  .TextInput-Input {
    margin-left: 0;
    margin-top: $dds-spacing-1;
    width: 100%;
  }

  .Subscription-Box {
    flex: 100%;
    max-width: 100%;
    width: 100%;
  }

  .Subscription-Icon {
    height: $dds-sizing-4;
    width: $dds-sizing-4;
  }

  .Subscriptions-Title {
    @include dds-font-size(1);
  }

  .Subscription-Button,
  .Subscriptions-EmailMessage {
    align-self: stretch;
    margin-left: 0;
    margin-right: 0;
  }
}
