@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';
@import './design-tokens';

.Gallery-UserName {
  @include dds-type-scale(0, 0);

  bottom: 0;
  color: $dds-color-white;
  font-weight: $dds-font-weight-semibold;
  left: 0;
  margin: 0 0 $dds-spacing-2 $dds-spacing-2;
  position: absolute;
  z-index: $z-index-photo-username;
}

.Gallery-PhotoWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.Gallery-PhotoBox {
  max-width: 100%;
  position: relative;
  width: $gallery-thumb-width;

  &::after {
    background-image: $gallery-photo-bg;
    border-radius: $dds-border-radius-global;
    bottom: 0;
    content: '';
    height: calc(100% - #{$dds-spacing-2});
    left: 0;
    margin-top: $dds-spacing-2;
    max-width: 100%;
    position: absolute;
    width: $gallery-thumb-width;
    z-index: $z-index-photobox;
  }
}

.Gallery-Photo {
  border-radius: $dds-border-radius-global;
  height: $gallery-photo-size;
  margin-top: $dds-spacing-2;
  max-width: 100%;
  object-fit: cover;
  width: $gallery-thumb-width;

  @include media-breakpoint-down(xs) {
    height: $gallery-photo-size-mobile;
  }
}
