/* ==========================================================================
   Shared styles
   ========================================================================== */

/* live indicator animation */
@mixin live-indicator {
  animation: 500ms ease-in-out 0s infinite alternate animation-live;
}

@keyframes animation-live {
  0% {
    filter: brightness(2);
    transform: scale(1);
  }

  100% {
    filter: brightness(1);
    transform: scale(0.75);
  }
}
