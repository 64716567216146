@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';
@import 'design-tokens';

// Vendor library overwrites
// sass-lint:disable class-name-format
.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-control-layers {
  &.leaflet-control {
    text-align: left;
  }
}

.Map--LocationSelectActive {
  .leaflet-container,
  .leaflet-interactive {
    cursor: crosshair;
  }
}
// sass-lint:enable class-name-format

.Map {
  height: $map-height;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: $map-height-md;
  }

  @include media-breakpoint-up(lg) {
    height: $map-height-lg;
  }
}

.Map-Path {
  stroke: $map-path-color;
}
