@import '~dde-app/common/styles/globals';
@import './design-tokens';

.Button {
  align-items: center;
  border-radius: $dds-button-border-radius;
  border-width: 0;
  cursor: pointer;
  display: flex;
  font-weight: $dds-font-weight-extrabold;
  justify-content: center;
  letter-spacing: $dds-button-letter-spacing;
  outline: none;
  position: relative;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
}

.Button-Icon--Left {
  img {
    margin-right: $dds-spacing-1;
  }
}

.Button-Icon--Right {
  img {
    margin-left: $dds-spacing-1;
  }
}

.Button--FullWidth {
  width: 100%;
}

.Button--Disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

// Button sizes

.Button--Default {
  @include dds-type-scale(body-m);
  padding: $dds-spacing-1 $dds-spacing-3;

  img {
    height: $dds-sizing-1 * 2.5;
    width: $dds-sizing-1 * 2.5;
  }
}

.Button--Small {
  @include dds-type-scale(body-sm-m);
  padding: $dds-spacing-0_5 $dds-spacing-2;

  img {
    height: $dds-sizing-2;
    width: $dds-sizing-2;
  }
}

.Button--Large {
  @include dds-type-scale(body-lg-m);
  padding: $dds-spacing-2 $dds-spacing-4;

  img {
    height: $dds-sizing-3;
    width: $dds-sizing-3;
  }
}

// Button style types

.Button--Primary {
  background-color: $dds-button-primary-bg;
  color: $dds-button-primary-color;

  &:hover {
    background-color: $dds-button-primary-bg-hover;
  }

  &:focus {
    background-color: $dds-button-primary-bg-active;
  }
}

.Button--Secondary {
  background-color: $dds-button-secondary-bg;
  color: $dds-button-secondary-color;

  &:hover {
    background-color: $dds-button-secondary-bg-hover;
  }

  &:focus {
    background-color: $dds-button-secondary-bg-active;
  }
}


.Button--Light {
  background-color: $dds-button-light-bg;
  box-shadow: inset 0 0 0 1px $dds-button-light-border;
  color: $dds-button-light-color;

  &:hover {
    background-color: $dds-button-light-bg-hover;
  }

  &:focus {
    background-color: $dds-button-light-bg-active;
  }
}
