@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';

.PhotoContestPreview {
  display: flex;
  height: max-content;
  justify-content: center;
  max-height: 100%;
  width: 95%;

  @include media-breakpoint-down(sm) {
    height: $dds-sizing-20;
    margin-bottom: $dds-spacing-3;
  }
}

.PhotoContestPreview-Image {
  overflow: hidden;
}

.PhotoContestPreview-FileInfo {
  margin-top: $dds-spacing-1;
  max-width: 100%;

  @include media-breakpoint-down(sm) {
    bottom: 0;
    height: auto;
    margin-bottom: $dds-spacing-3;
    position: relative;
  }
}

.PhotoContestPreview-FileInfo--Text {
  display: inline-block;
  margin-right: $dds-spacing-2;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.PhotoContestPreview-TrashIcon {
  cursor: pointer;
  margin-top: -$dds-spacing-2;
}
