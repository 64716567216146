@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';
@import 'design-tokens';

@keyframes animation-pulse {
  0% {
    background-color: $skeleton-bg;
  }

  100% {
    background-color: lighten($skeleton-bg, 5);
  }
}

.Skeleton-TitleContainer,
.Skeleton-MapContentContainer {
  animation: animation-pulse 750ms ease-in-out alternate infinite;
  background-color: $skeleton-bg;
}

.Skeleton-MapContentContainer {
  &.MapSection-MapContentContainer {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.Skeleton-Title {
  height: $dds-sizing-4_5;
}

.Skeleton-Map {
  background-color: $skeleton-map-bg;
}

.Skeleton-MapContest {
  animation: animation-pulse 750ms ease-in-out alternate infinite;
  border: 0;
  min-height: $skeleton-contest-min-height;
}
