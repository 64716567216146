@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';
@import './design-tokens';

.PhotoContestModal-ContentBold,
.PhotoContestModal-ParagraphBold {
  font-weight: $dds-font-weight-bold;
}

.PhotoContestModal-PhotoImage {
  height: max-content;
  margin: 0 $dds-spacing-2 $dds-spacing-2;
}

.PhotoContestModal-PrizeImage {
  margin-bottom: $dds-spacing-3;
  max-width: 100%;
}

.PhotoContestModal-ImageBox {
  display: flex;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
}

.PhotoContestModal-Overlay {
  background-color: $photo-contest-modal-overlay-bg;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-modal;
}

.PhotoContestModal-Box {
  align-self: center;
  background-color: $dds-color-white;
  border-radius: $dds-border-radius-global;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: calc(100vh - #{$dds-spacing-4});
  max-width: calc(100vw - #{$dds-spacing-4});
  overflow-y: auto;
  position: absolute;
  z-index: $z-index-modal-box;

  @include media-breakpoint-down(sm) {
    bottom: $dds-spacing-2;
    left: $dds-spacing-2;
    right: $dds-spacing-2;
    top: $dds-spacing-2;
  }
}

.PhotoContestModal-Content {
  margin: $dds-spacing-4;
}

.PhotoContestModal-Text {
  margin: 0;
}

.PhotoContestModal-Form {
  margin: $dds-spacing-6 0;
}

.PhotoContestModal-DropzoneBox {
  border: 3px dotted $dds-color-gray-300;
  border-radius: $dds-border-radius-global;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: $dds-spacing-2;
  min-height: $dds-sizing-5;
  width: 100%;

  &.PhotoContestModal-Rejected {
    border-color: $dds-color-red;
  }

  @include media-breakpoint-down(sm) {
    border: 0;
    height: auto;
    width: max-content;
  }
}

.PhotoContestModal-FileButton {
  align-self: start;
  display: flex;
  margin: $dds-spacing-1 0;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.PhotoContestModal-DragText {
  @include dds-type-scale(2);
  color: $dds-color-gray-400;
  font-weight: $dds-font-weight-extrabold;
  letter-spacing: -0.085rem;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.PhotoContestModal-FileText {
  @include dds-type-scale(-2);
  color: $dds-color-gray-500;

  @include media-breakpoint-down(sm) {
    text-align: left;
  }
}

.PhotoContestModal-BrowseButton {
  color: $dds-color-gray-500;
  cursor: pointer;
  margin-left: $dds-spacing-1;
  text-decoration: underline;
}

.PhotoContestModal-TextInput {
  margin-bottom: $dds-spacing-2;
}

.PhotoContestModal-Title {
  margin-bottom: $dds-spacing-1;
}

.PhotoContestModal-OpenGalleryButton {
  display: inline-block;
  margin-top: $dds-spacing-10;
}

.PhotoContestModal-Info {
  @include dds-type-scale(-2);
  color: $dds-color-gray-500;
  line-height: $dds-line-height-condensed;
  text-align: left;
}

.PhotoContestModal-TermsCheckbox {
  margin: $dds-spacing-2 0;
}

.PhotoContestModal-TermsText {
  margin-left: $dds-spacing-1;
}

.PhotoContestModal-FormButton {
  margin-top: $dds-spacing-2;
}

.PhotoContestModal-CloseBtn {
  cursor: pointer;
  position: absolute;
  right: $dds-spacing-1;
  top: $dds-spacing-1;
}

.PhotoContestModal-FileErrorList {
  color: $dds-color-red;
  margin: 0;
  padding: 0;
}

.PhotoContestModal-FileError {
  @include dds-type-scale(-1);
  list-style-type: none;
  margin-bottom: $dds-spacing-1;
}
