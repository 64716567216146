@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/swiper/swiper';
@import 'node_modules/swiper/components/lazy/lazy';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';
@import './design-tokens';

.Slider {
  background-color: $slider-bg-color;
  height: 100%;
  left: 0;
  max-width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-slider;
}

.Slider-SliderContainer {
  height: 100vh;
}

.Slider-Slide {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$dds-spacing-5});
  justify-content: center;
  left: 0;
  right: 0;
  width: auto;
}

.Slider-SlideWrapper {
  display: flex;
  flex-direction: column;
}

.Slider-SlidePhoto {
  align-self: center;
  max-height: calc(100vh - #{$dds-spacing-10 * 2.5});
  max-width: calc(100vw - #{$dds-spacing-20});
}


.Slider-SlideText {
  @include dds-type-scale(-1);

  align-self: center;
  bottom: $dds-spacing-5;
  color: $dds-color-white;
  position: absolute;
}

.Slider-CloseButton {
  cursor: pointer;
  position: absolute;
  right: $dds-spacing-2;
  top: $dds-spacing-2;
  z-index: $z-index-slider-button;

  @include media-breakpoint-up(md) {
    right: $dds-spacing-5;
    top: $dds-spacing-5;
  }
}

.Slider-ArrowLeft {
  cursor: pointer;
  left: $dds-spacing-1;
  position: absolute;
  top: 50%;
  z-index: $z-index-slider-button;

  @include media-breakpoint-up(md) {
    left: $dds-spacing-5;
  }
}

.Slider-ArrowRight {
  cursor: pointer;
  position: absolute;
  right: $dds-spacing-1;
  top: 50%;
  z-index: $z-index-slider-button;

  @include media-breakpoint-up(md) {
    right: $dds-spacing-5;
  }
}

.Slider-SubpageButton {
  align-self: center;
  display: flex;
  justify-content: center;
  margin-top: $dds-spacing-3;
}

// sass-lint:disable-block class-name-format
.swiper-container {
  height: calc(100vh - #{$dds-spacing-10});
  max-width: calc(100vw - #{$dds-spacing-20});

  @include media-breakpoint-down(md) {
    max-width: calc(100vw - #{$dds-spacing-5});
  }
}
