@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';

.Header {
  background-image: url('~dde-app/app/assets/bg-header.jpg');
  background-size: cover;
  margin-bottom: $dds-spacing-10;
  position: relative;

  @include media-breakpoint-up(md) {
    &::after {
      background-image: radial-gradient(
        circle at 0 0,
        $dds-color-primary,
        darken($dds-color-primary, 5%),
      );
      clip-path: polygon(0 0, 100% 0, calc(100% - #{$dds-spacing-15}) 100%, 0% 100%);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: calc(50% + #{$dds-spacing-15});
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-15;
  }


  @include media-breakpoint-up(xl) {
    &::after {
      clip-path: polygon(0 0, 100% 0, calc(100% - #{$dds-spacing-30}) 100%, 0% 100%);
      width: calc(50% + #{$dds-spacing-30});
    }
  }
}

.Hero {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: $z-index-hero;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.Hero-ContentWrapper {
  background-image: radial-gradient(
    circle at 0 0,
    $dds-color-primary,
    darken($dds-color-primary, 5%),
  );
  clip-path: polygon(0 $dds-spacing-6, 100% 0, 100% 100%, 0% 100%);
  color: $dds-color-white;
  margin-left: -$dds-spacing-2;
  margin-right: -$dds-spacing-2;
  order: 1;
  padding: $dds-spacing-8 $dds-spacing-2 $dds-spacing-1;

  @include media-breakpoint-up(md) {
    align-self: center;
    background-image: none;
    clip-path: none;
    margin: 0;
    order: 0;
    padding: 0;
    width: 50%;
  }
}

.Hero-Content {
  margin-bottom: $dds-spacing-2;
  margin-top: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    padding-right: $dds-spacing-2;
  }

  @include media-breakpoint-up(lg) {
    padding-right: $dds-spacing-4;
  }
}

.Hero-ImageWrapper {
  margin: -$dds-spacing-6 auto 0;
  position: relative;
  width: 50%;
  z-index: $z-index-hero;

  @include media-breakpoint-up(md) {
    margin: $dds-spacing-4 auto;
    right: -$dds-spacing-4;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: -$dds-spacing-6;
    right: -$dds-spacing-4;
  }
}

.Hero-Image {
  max-width: 100%;
}

.Hero-Image--Animated {
  @include dds-pos(relative, $top: $dds-spacing-4);
  animation: 3s ease-in-out 0s infinite alternate animation-fly;
  transform: translateY(-$dds-spacing-4);

  @include media-breakpoint-up(md) {
    top: 0;
    transform: translateY(-$dds-spacing-4);
  }
}

@keyframes animation-fly {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-$dds-spacing-4);
  }
}

@keyframes animation-sky {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 200%;
  }
}
