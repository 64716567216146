/* ==========================================================================
   Functions
   ========================================================================== */

/**
 * REM CONVERTER
 * Function for converting values from px to rem
 */

@function rem($pixels, $context: $dds-font-size-root) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}
