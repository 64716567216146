/* ==========================================================================
   Design Tokens
   ========================================================================== */

/* Colors
   ========================================================================== */

/**
 * Named colors
 */

$dds-color-primary:   #23b40c;
$dds-color-secondary: #e7343f;
$dds-color-black:     #000000;
$dds-color-gray-900:  #18181b;
$dds-color-gray-800:  #27272a;
$dds-color-gray-700:  #3f3f45;
$dds-color-gray-600:  #52525a;
$dds-color-gray-500:  #717179;
$dds-color-gray-400:  #a1a1a9;
$dds-color-gray-300:  #d4d4d8;
$dds-color-gray-200:  #e4e4e7;
$dds-color-gray-100:  #f4f4f5;
$dds-color-white:     #ffffff;
$dds-color-red:       #cd0000;
$dds-color-blue:      #1976d2;

/**
 * Alert colors
 */

$dds-color-error:     #cd0000;
$dds-color-warning:   #f8a424;

/**
 * UI colors
 */

$dds-color-text-primary:           $dds-color-black !default;
$dds-color-text-secondary:         $dds-color-gray-500 !default;
$dds-color-text-body:              $dds-color-gray-900 !default;
$dds-color-border-input:           $dds-color-gray-500 !default;
$dds-color-input-text-placeholder: $dds-color-gray-300 !default;


/* Sizing
   ========================================================================== */

/**
* This is a base (micro-grid) for all measurements in UI Components
* base unit = 8px
*/

$dds-sizing-base: 0.5rem !default;

$dds-sizing-0_5: $dds-sizing-base * 0.5 !default; // 4px
$dds-sizing-1:   $dds-sizing-base * 1 !default; // 8px
$dds-sizing-1_5: $dds-sizing-base * 1.5 !default; // 12px
$dds-sizing-2:   $dds-sizing-base * 2 !default; // 16px
$dds-sizing-2_5: $dds-sizing-base * 2.5 !default; // 20px
$dds-sizing-3:   $dds-sizing-base * 3 !default; // 24px
$dds-sizing-3_5: $dds-sizing-base * 3.5 !default; // 28px
$dds-sizing-4:   $dds-sizing-base * 4 !default; // 32px
$dds-sizing-4_5: $dds-sizing-base * 4.5 !default; // 36px
$dds-sizing-5:   $dds-sizing-base * 5 !default; // 40px
$dds-sizing-6:   $dds-sizing-base * 6 !default; // 48px
$dds-sizing-7:   $dds-sizing-base * 7 !default; // 56px
$dds-sizing-8:   $dds-sizing-base * 8 !default; // 64px
$dds-sizing-9:   $dds-sizing-base * 9 !default; // 72px
$dds-sizing-10:  $dds-sizing-base * 10 !default; // 80px
$dds-sizing-15:  $dds-sizing-base * 15 !default; // 120px
$dds-sizing-20:  $dds-sizing-base * 20 !default; // 160px
$dds-sizing-25:  $dds-sizing-base * 25 !default; // 200px
$dds-sizing-30:  $dds-sizing-base * 30 !default; // 240px


/* Spacing
   ========================================================================== */

/**
 * Spacing based on $dds-sizing-base unit
 * Usage: margins, paddings, positioning
 */

$dds-spacing-base: $dds-sizing-base !default;

$dds-spacing-0_25:$dds-spacing-base * 0.25 !default; // 2px
$dds-spacing-0_5: $dds-spacing-base * 0.5 !default; // 4px
$dds-spacing-1:   $dds-spacing-base * 1 !default; // 8px
$dds-spacing-1_5: $dds-spacing-base * 1.5 !default; // 12px
$dds-spacing-2:   $dds-spacing-base * 2 !default; // 16px
$dds-spacing-2_5: $dds-spacing-base * 2.5 !default; // 20px
$dds-spacing-3:   $dds-spacing-base * 3 !default; // 24px
$dds-spacing-3_5: $dds-spacing-base * 3.5 !default; // 28px
$dds-spacing-4:   $dds-spacing-base * 4 !default; // 32px
$dds-spacing-4_5: $dds-spacing-base * 4.5 !default; // 36px
$dds-spacing-5:   $dds-spacing-base * 5 !default; // 40px
$dds-spacing-6:   $dds-spacing-base * 6 !default; // 48px
$dds-spacing-7:   $dds-spacing-base * 7 !default; // 56px
$dds-spacing-8:   $dds-spacing-base * 8 !default; // 64px
$dds-spacing-9:   $dds-spacing-base * 9 !default; // 72px
$dds-spacing-10:  $dds-spacing-base * 10 !default; // 80px
$dds-spacing-15:  $dds-spacing-base * 15 !default; // 120px
$dds-spacing-20:  $dds-spacing-base * 20 !default; // 160px
$dds-spacing-25:  $dds-spacing-base * 25 !default; // 200px
$dds-spacing-30:  $dds-spacing-base * 30 !default; // 240px


/* Typography & font-sizing
   ========================================================================== */

$dds-font-family-base: 'Open Sans', 'Segoe UI', Tahoma, sans-serif !default;

$dds-font-size-root:              16px !default;
$dds-font-size-base:              15px !default;
$dds-line-height-large:           $dds-font-size-root * 1.75 !default; // 28px
$dds-line-height-base:            $dds-font-size-root * 1.5 !default; // 24px
$dds-line-height-list:            $dds-font-size-root * 1.25 !default; // 20px
$dds-line-height-condensed:       $dds-font-size-root * 1 !default; // 16px
$dds-line-height-condensed-small: $dds-font-size-root * 0.75 !default; // 12px

$dds-font-weight-regular:   400 !default;
$dds-font-weight-semibold:  600 !default;
$dds-font-weight-bold:      700 !default;
$dds-font-weight-extrabold: 800 !default;

$dds-type-scales: (
  -4: 10px,
  -3: 13px,
  -2: 14px,
  -1: 15px,
  0:  16px,
  1:  18px,
  2:  20px,
  3:  24px,
  4:  28px,
  5:  32px,
  6:  36px,
  7:  56px,

  /**
   * Aliases
   */

  /**
   * Mobile sizes
   */

  xxsmall-m: 10px,
  xsmall-m:  13px,
  small-m:   14px,
  body-sm-m: 16px,
  body-m:    16px,
  body-lg-m: 18px,
  h6-m:      15px,
  h5-m:      16px,
  h4-m:      18px,
  h3-m:      20px,
  h2-m:      28px,
  h1-m:      36px,

  /**
   * Desktop sizes
   */

  xxsmall:  10px,
  xsmall:  13px,
  small:   14px,
  body-sm: 16px,
  body:    18px,
  body-lg: 20px,
  h6:      16px,
  h5:      18px,
  h4:      20px,
  h3:      24px,
  h2:      32px,
  h1:      56px,
) !default;


/* Borders
   ========================================================================== */

$dds-border-radius-global: 0.25rem !default; // 4px
$dds-border-input:         1px solid $dds-color-border-input !default;


/**
 * Z-index layers
 */

$z-index-live-indicator-before: 1;
$z-index-live-indicator-after: 2;
$z-index-navbar: 2;
$z-index-hero: 2;
$z-index-photobox: 2;
$z-index-photo-username: 3;
$z-index-map: 1001;
$z-index-slider: 2001;
$z-index-slider-button: 2002;
$z-index-modal: 3001;
$z-index-modal-box: 3002;
