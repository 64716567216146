@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';

.HowTo {
  background-color: $dds-color-white;
  margin: $dds-spacing-8 0;
  margin-bottom: $dds-spacing-10;

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-15;
  }
}

.HowTo-Icon {
  align-self: center;
  height: $dds-sizing-6;
  margin-bottom: $dds-spacing-4;
  width: $dds-sizing-6;
}

.HowTo-ContentBox {
  background-color: $dds-color-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: $dds-spacing-4 0;
}

.HowTo-ArrowIconBox {
  background-color: $dds-color-white;
  display: flex;
  justify-content: center;
}

.HowTo-ArrowIcon--Bottom {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.HowTo-ArrowIcon--Right {
  align-self: center;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.HowTo-Subtitle {
  margin-bottom: $dds-spacing-1;
  text-align: center;

  @include media-breakpoint-up(xl) {
    text-align: left;
  }
}

.HowTo-Text {
  text-align: center;

  @include media-breakpoint-up(xl) {
    text-align: left;
  }
}
