/* ==========================================================================
   Fonts & Typograhy
   ========================================================================== */

// sass-lint:disable no-url-domains, no-url-protocols
@import url('//fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700,800');

html {
  font-size: $dds-font-size-root;
}

body {
  @include dds-type-scale(body-m);
  color: $dds-color-text-body;
  font-family: $dds-font-family-base;
  font-feature-settings: 'liga', 'clig';
  font-variant-ligatures: common-ligatures;
  font-weight: $dds-font-weight-regular;
  // sass-lint:disable-block no-vendor-prefixes property-sort-order
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(body);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $dds-font-weight-extrabold;
  text-transform: uppercase;
}

h1 {
  @include dds-type-scale(h1-m, 1);
  letter-spacing: -0.15rem;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h1, 1);
  }
}

h2 {
  @include dds-type-scale(h2-m, 1);
  letter-spacing: -0.12rem;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h2, 1);
  }
}

h3 {
  @include dds-type-scale(h3-m, 1);
  letter-spacing: -0.085rem;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h3, 1);
  }
}

h4 {
  @include dds-type-scale(h4-m, 1);

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h4, 1);
  }
}

h5 {
  @include dds-type-scale(h5-m, 1);

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h5, 1);
  }
}

h6 {
  @include dds-type-scale(h6-m, 1);

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h6, 1);
  }
}

p {
  @include dds-type-scale(body-m, 1);
  @include media-breakpoint-up(lg) {
    @include dds-type-scale(body, 1);
  }
}

small {
  @include dds-type-scale(small-m);
}

strong {
  font-weight: $dds-font-weight-bold;
}
