@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';
@import '~dde-app/common/styles/shared';

.ContestStatus {
  background-color: $dds-color-gray-900;
  border-radius: $dds-border-radius-global;
  padding: $dds-spacing-1;
}

.ContestStatus-ContentWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ContestStatus-Content {
  @include dds-type-scale(0);
  align-items: center;
  display: flex;

  @include media-breakpoint-down(md) {
    align-items: start;
    margin-bottom: $dds-spacing-1;
  }
}

.ContestStatus-Icon {
  @include live-indicator;
  @include dds-box($dds-sizing-3);
}

.ContestStatus-Info {
  color: $dds-color-white;
  font-weight: $dds-font-weight-bold;
  margin-left: $dds-spacing-1;
  text-transform: uppercase;
}

.ContestStatus-Button {
  margin-left: $dds-spacing-3;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
    margin-left: 0;
    padding: $dds-spacing-0_5;
    width: 100%;
  }
}

/* live indicator animation */

// TODO: move to components
$indicator-dot-size: 8px;
$indicator-size: 24px;
$indicator-pulse-border: 4px;

.LiveIndicator {
  height: $indicator-size;
  position: relative;
  width: $indicator-size;

  // pulse
  &::before {
    animation: pulse 1s ease-out infinite;
    animation-delay: 1000ms;
    background: transparent;
    border: $indicator-pulse-border solid $dds-color-red;
    border-radius: 100%;
    content: '';
    height: $indicator-size;
    left: calc(0 - (#{$indicator-pulse-border}));
    opacity: 0;
    position: absolute;
    top: calc(0 - (#{$indicator-pulse-border}));
    width: $indicator-size;
    z-index: $z-index-live-indicator-before;
  }

  // dot
  &::after {
    animation: dot 1s ease-out infinite;
    background-color: $dds-color-red;
    border-radius: 100%;
    content: '';
    height: $indicator-dot-size;
    left: calc(50% - (#{$indicator-dot-size} / 2));
    position: absolute;
    top: calc(50% - (#{$indicator-dot-size} / 2));
    width: $indicator-dot-size;
    z-index: $z-index-live-indicator-after;
  }
}

@keyframes pulse {
  0%   {
    opacity: 0;
    transform: scale(0, 0);
  }

  35%  {
    opacity: 1;
  }

  65%  {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
}

@keyframes dot {
  0%   {
    opacity: 0.75;
    transform: scale(0.75, 0.75);
  }

  50%  {
    opacity: 1;
    transform: scale(1.25, 1.25);
  }

  100% {
    opacity: 0.75;
    transform: scale(0.75, 0.75);
  }
}
