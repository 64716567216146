@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';

.PhotoContestProgress {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.PhotoContestProgress-Wrapper {
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-bottom: $dds-spacing-3;
  }
}

.PhotoContestProgress-Label {
  @include dds-type-scale(2);
  color: $dds-color-gray-400;
  font-weight: $dds-font-weight-extrabold;
  letter-spacing: -0.085rem;
  text-transform: uppercase;
}
