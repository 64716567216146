@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';

.PhotoContestConfirmation-Content {
  margin: $dds-spacing-4;
}

.PhotoContestConfirmation-Icon {
  margin: $dds-spacing-2 0;
}

.PhotoContestConfirmation-Title {
  margin-bottom: $dds-spacing-1;
}

.PhotoContestConfirmation-Text {
  margin: 0;
}

.PhotoContestConfirmation-CloseButton {
  display: inline-block;
  margin-top: $dds-spacing-2;
}
