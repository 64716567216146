@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';

.Footer {
  background-color: $dds-color-primary;
  display: flex;
  flex-direction: column;
  padding: $dds-spacing-5 0;
}

.Footer-Logo {
  justify-content: left;
  margin-bottom:  $dds-sizing-4;
}

.Footer-SocialIcons {
  display: flex;
  list-style-type: none;
  padding: 0;

  @include media-breakpoint-up(md) {
    align-self: flex-end;
  }
}

.Footer-Partner {
  @include dds-font-size(xxsmall-m);
  align-items: center;
  color: $dds-color-white;
  display: flex;
  flex-direction: column;
  font-weight: $dds-font-weight-bold;
  list-style-type: none;
  padding: 0;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.Footer-PartnerLink {
  &:hover {
    color: $dds-color-white;
  }
}

.Footer-PartnerIcon {
  display: block;
  height: $dds-sizing-10;
  margin: 0 auto;
}

.Footer-PartnerName {
  color: $dds-color-white;
}

.Footer-SocialIcon {
  flex-shrink: 0;
  margin: 0  $dds-sizing-3 0 0;

  &:last-of-type {
    margin-right: 0;
  }
}

.Footer-WidgetTitle {
  @include dds-font-size(h5-m);
  color: $dds-color-white;
  font-weight: $dds-font-weight-extrabold;
  text-transform: uppercase;
}

.Footer-WidgetItems {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
}

.Footer-WidgetItem {
  flex-shrink: 0;
}

.Footer-Link,
.Footer-Link:hover {
  @include dds-font-size(small-m);
  color: $dds-color-white;
}
