@import '~dde-app/common/styles/globals';
@import './design-tokens';

.ProgressBar-Percentage {
  background-color: $progress-bar-percentage-bg;
  border-radius: $progress-bar-percentage-height / 2;
  height: $progress-bar-percentage-height;
  margin-bottom: $dds-spacing-1;
  position: relative;
}

.ProgressBar-Percentage--Filled {
  background-color: $progress-bar-percentage-filled-bg;
  border-radius: $progress-bar-percentage-height / 2;
  height: 100%;
  left: 0;
  position: absolute;
}

.ProgressBar-Bottom {
  @include dds-type-scale(xxsmall-m, 0, $line-height: $dds-line-height-condensed);
  color: $dds-color-text-primary;
  display: flex;
  justify-content: space-between;
}

.ProgressBar-BottomTitle {
  padding: 0 $dds-spacing-1;
  text-align: center;
}
