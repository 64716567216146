/* ==========================================================================
   Bootstrap Custom Grid
   ========================================================================== */

/**
 * For tablet and mobiles gutter is 8px
 */

/* sass-lint:disable class-name-format */
.container {
  padding-left: $dds-sizing-2;
  padding-right: $dds-sizing-2;

  @include media-breakpoint-up(md) {
    padding-left: $dds-sizing-3;
    padding-right: $dds-sizing-3;
  }

  @include media-breakpoint-up(lg) {
    padding-left: $dds-sizing-6;
    padding-right: $dds-sizing-6;
  }
}

div,
section {
  &[class*='col'] {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;

    @include media-breakpoint-up(md) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
  }
}

.row {
  margin-left: -$grid-gutter-width / 2;
  margin-right: -$grid-gutter-width / 2;

  @include media-breakpoint-up(md) {
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;
  }
}
