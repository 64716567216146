@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';
@import './design-tokens';

.PhotoContestPage {
  margin-bottom: $dds-spacing-8;
  margin-top: $dds-spacing-8;
  min-height: 90vh;
  padding-bottom: $dds-spacing-20;
  width: $photocontest-container-width;
}

.PhotoContestPage-Title {
  margin-bottom: $dds-spacing-10;
}

.PhotoContestPage-UserName {
  @include dds-type-scale(0, 0);

  bottom: 0;
  color: $dds-color-white;
  font-weight: $dds-font-weight-semibold;
  left: 0;
  margin: 0 0 $dds-spacing-2 $dds-spacing-2;
  position: absolute;
  z-index: $z-index-photo-username;
}

.PhotoContestPage-PhotoWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.PhotoContestPage-PhotoBox {
  position: relative;
  width: 100%;

  &::after {
    background-image: $gallery-photo-bg;
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    margin-top: $dds-spacing-2;
    position: absolute;
    width: 100%;
    z-index: $z-index-photobox;
  }
}

.PhotoContestPage-Photo {
  border-radius: $dds-border-radius-global;
  height: $gallery-photo-size;
  margin-top: $dds-spacing-2;
  max-width: 100%;
  object-fit: cover;
  width: 100%;

  @include media-breakpoint-down(xs) {
    height: $gallery-photo-size-mobile;
  }
}
