@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';

.DataPair {
  display: block;
}

.DataPair-Data {
  @include dds-type-scale(h2-m, 1);
  display: block;
  font-weight: $dds-font-weight-extrabold;
  letter-spacing: -0.12rem;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h2, 1);
  }
}

.DataPair-Label {
  @include dds-type-scale(h6-m);
  display: block;
  white-space: nowrap;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h6);
  }
}
