@import '~dde-app/common/styles/globals';
@import './design-tokens';

.Checkbox {
  @include dds-type-scale(small-m);
  align-items: center;
  color: $checkbox-color;
  cursor: pointer;
  display: flex;
  margin: 0;
  user-select: none;
}

.Checkbox-Input {
  @include dds-pos(absolute);
  visibility: hidden;

  &:checked {
    & + .Checkbox-Field {
      background-color: $checkbox-field-checked-bg;
      border-color: $checkbox-field-checked-border;

      .Checkbox-Icon {
        opacity: 1;
      }
    }
  }

  &:disabled {
    & + .Checkbox-Field {
      cursor: not-allowed;
      opacity: .5;
    }
  }
}

.Checkbox-Label {
  line-height: $dds-line-height-condensed;
}

.Checkbox-LabelRequired {
  color: $dds-color-error;
  margin-left: $dds-spacing-0_5;
}

.Checkbox-Field {
  @include dds-box($dds-sizing-3);
  background-color: $checkbox-field-bg;
  border: $dds-border-input;
  border-radius: $dds-border-radius-global;
  flex: 0 0 $dds-sizing-3;
  margin-right: $dds-sizing-1;
  position: relative;
}

.Checkbox-Icon {
  @include dds-pos(absolute, $top: 50%, $left: 50%);
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
}
