/* ==========================================================================
     Bootstrap Custom Variables
   ========================================================================== */

@import '~dde-app/common/styles/globals';

/**
 * These settings are used to override Bootstrap's default variables
 */

$container-max-widths: (
  xs: 100%,
  xl: 1408px,
);

$grid-breakpoints: (
  xs:  0,
  sm:  480px,
  md:  768px,
  lg:  1200px,
  xl:  1480px,
  xxl: 1920px
);

$grid-columns:      12;
$grid-gutter-width: $dds-spacing-2;
$spacer:            $dds-spacing-1;
