@import '~dde-app/common/styles/globals';

$map-section-container-bg: $dds-color-primary;
$map-section-container-mobile-bg: $dds-color-white;
$map-section-border-radius-desktop: 2 * $dds-border-radius-global;
$map-section-title-color: $dds-color-white;
$map-section-live-label-color: $dds-color-white;
$map-section-live-label-letter-spacing: -1.1px;
$map-section-live-label-bg: $dds-color-black;
$map-section-contest-bg: $dds-color-white;
$map-section-contest-border-color: $dds-color-gray-300;
