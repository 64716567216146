@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';

.EventInfo {
  background-color: $dds-color-white;
  margin-bottom: $dds-spacing-10;

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-15;
  }
}

.EventInfo-Image {
  margin: $dds-spacing-4 0;
  max-width: 100%;
}

.EventInfo-Title {
  @include dds-type-scale(h2-m, 1);
  font-weight: $dds-font-weight-extrabold;
  margin-bottom: $dds-spacing-1;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h2, 1);
  }
}

.EventInfo-Subtitle {
  @include dds-type-scale(h6-m);
  margin-bottom: $dds-spacing-3;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h6, 1);
  }
}

.EventInfo-TopData {
  .DataPair {
    margin-top: $dds-spacing-4;
  }

  .DataPair:last-of-type {
    margin-bottom: $dds-spacing-4;
  }
}

.EventInfo-BottomData {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    align-items: center;
  }
}

.EventInfo-Skywalker {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: $dds-spacing-10;
}

.EventInfo-SkywalkerInfo {
  @include dds-type-scale(body-lg-m);
  margin-top: $dds-spacing-2;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(body-lg);
  }
}

.EventInfo-SkywalkerName,
.EventInfo-SkywalkerName:hover {
  @include dds-type-scale(body-lg-m);
  color: $dds-color-gray-900;
  font-weight: bold;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(body-lg);
  }
}

.EventInfo-Capacity {
  margin-bottom: $dds-spacing-4;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}
