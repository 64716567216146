@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import '~dde-app/common/styles/globals';

.NavBar {
  background-image: radial-gradient(
    circle at 0 0,
    $dds-color-primary,
    darken($dds-color-primary, 5%),
  );
  padding: $dds-spacing-1 0;
  position: relative;
  z-index: $z-index-navbar;

  @include media-breakpoint-up(md) {
    padding: $dds-spacing-2 0;
  }

  .Header--WithHero & {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{$dds-spacing-6}), 0% 100%);
    padding: $dds-spacing-1 0 $dds-spacing-8;

    @include media-breakpoint-up(md) {
      background-image: none;
      clip-path: none;
      padding: $dds-spacing-2 0;
    }
  }
}

.NavBar-Container {
  @include media-breakpoint-up(md) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.NavBar-Logo {
  margin-bottom: $dds-spacing-1;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.NavBar-NavWrapper {
  position: relative;

  &::after {
    @include dds-pos(absolute, $top: 0, $right: 0);
    background: linear-gradient(90deg, transparent 0%, darken($dds-color-primary, 5%) 100%);
    content: '';
    display: block;
    height: 100%;
    width: $dds-sizing-2;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.NavBar-Nav {
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  margin: 0;
  overflow: auto;
  padding: 0;
}

.NavBar-NavItem {
  margin-right: $dds-spacing-4;

  &:last-child {
    margin-right: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-right: $dds-spacing-6;

    &:last-child {
      margin-right: 0;
    }
  }
}

.NavBar-NavLink {
  @include dds-type-scale(-3);
  color: $dds-color-white;
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    color: $dds-color-white;
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(0);
  }
}
