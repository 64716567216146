/* ==========================================================================
   Custom Mixins
   ========================================================================== */

/**
 * dds-type-scale
 * Sets proper font-size & line-height based on global typography settings
 * Line-height (unitless) is calculated based on font-size to fit into vertical grid
 * Use margin-bottom property to include additional line below
 * Use line-height property to adjust leading (line-height)
 *
 * Examples:
 * @include dds-type-scale(1), @include dds-type-scale(h1,1);
 */

@mixin dds-type-scale($scale, $margin-bottom: 0, $line-height: $dds-line-height-base) {
  // returns number of half-lines given font-size occupies
  $line-count: ceil(map-get($dds-type-scales, $scale) / ($line-height / 2));
  $current-font-size: map-get($dds-type-scales, $scale);
  $current-line-height: ($line-count * ($line-height / 2)) / $current-font-size;

  font-size: rem($current-font-size);
  line-height: $current-line-height + 0.00001;

  @if ($margin-bottom != 0) {
    margin: 0 0 rem($margin-bottom * $dds-line-height-base);
  } @else {
    margin: 0;
  }
}

/**
 * dds-font-size
 * Retrieves proper font-size from font-sizing map
 *
 * Examples:
 * @include dds-font-size(small);
 */

@mixin dds-font-size($scale) {
  $current-font-size: map-get($dds-type-scales, $scale);
  font-size: rem($current-font-size);
}

/**
 * dds-BOX
 * Sets both height and width of element
 */

@mixin dds-box($width, $height: $width) {
  height: $height;
  width: $width;
}

/**
 * dds-FORM-PLACEHOLDER
 * Sets both height and width of element
 */

@mixin dds-form-placeholder($color: $dds-color-input-text-placeholder) {
  // sass-lint:disable-block no-vendor-prefixes
  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
  }

  &::-ms-input-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

/**
 * dds-POS
 * Shortcut for positioning properties
 */

@mixin dds-pos($position, $top: null, $right: null, $bottom: null, $left: null) {
  bottom: $bottom;
  left: $left;
  position: $position;
  right: $right;
  top: $top;
}
