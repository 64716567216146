@import 'node_modules/bootstrap/scss/mixins';
@import '~dde-app/common/styles/vendor/bootstrap-custom-variables';
@import 'design-tokens';
@import '~dde-app/common/styles/shared';

.MapSection {
  margin-bottom: $dds-spacing-10;

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-15;
  }
}

.MapSection-TitleContainer {
  align-items: center;
  background-color: $map-section-container-bg;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $dds-spacing-3 0;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    padding: $dds-spacing-4 0;
  }

  @include media-breakpoint-up(lg) {
    border-top-left-radius: $map-section-border-radius-desktop;
    border-top-right-radius: $map-section-border-radius-desktop;
  }
}

.MapSection-Title {
  color: $map-section-title-color;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.MapSection-LiveLabel {
  @include dds-type-scale(-1);
  align-items: center;
  background-color: $map-section-live-label-bg;
  border-radius: $dds-sizing-2;
  color: $map-section-live-label-color;
  display: flex;
  font-weight: $dds-font-weight-extrabold;
  height: $dds-sizing-4;
  letter-spacing: $map-section-live-label-letter-spacing;
  margin-left: 0;
  margin-top: $dds-spacing-1;
  padding: $dds-spacing-0_5 $dds-spacing-2 $dds-spacing-0_5 $dds-spacing-1;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    margin-left: $dds-spacing-1_5;
    margin-top: 0;
  }
}

.MapSection-LiveLabelIcon {
  @include live-indicator;
  @include dds-box($dds-sizing-3);
  margin-right: $dds-spacing-1;
}

// sass-lint:disable no-important
.MapSection-MapContentContainer {
  background-color: $map-section-container-bg;
  border-bottom-left-radius: $map-section-border-radius-desktop;
  border-bottom-right-radius: $map-section-border-radius-desktop;
  padding-bottom: $dds-spacing-6;

  @include media-breakpoint-down(md) {
    border-radius: 0;
    padding-bottom: $dds-spacing-0_25;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.MapSection-MapContentWrapper {
  margin: 0 auto;
  position: relative;

  @include media-breakpoint-down(md) {
    max-width: calc(100% - #{$dds-spacing-0_5}) !important;
  }
}
// sass-lint:enable no-important

.MapSection-Contest {
  background: $map-section-contest-bg;
  overflow: auto;
  padding: $dds-spacing-2;
  position: relative;
  z-index: $z-index-map;

  @include media-breakpoint-up(md) {
    @include dds-pos(absolute, $bottom: $dds-spacing-2, $right: $dds-spacing-2, $top: $dds-spacing-2);
    border: 1px solid $map-section-contest-border-color;
    border-radius: $map-section-border-radius-desktop;
    padding: $dds-spacing-1;
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 45%;
  }

  @include media-breakpoint-up(xl) {
    width: 40%;
  }
}
